//import Haru from '../asset/Logo/01._Penerbit_Haru.png'
import POS from '../asset/Logo/02._Pos_Bloc.png'
import Clan from '../asset/Logo/Book_Clan_-_Transparent1.png'
//import Mojok from '../asset/Logo/Buku_Mojok.png'
//import Bukune from '../asset/Logo/Bukune.png'
import Detectives from '../asset/Logo/Detectives_ID.png'
import Ea from '../asset/Logo/EA_Books.png'
//import Gagasmedia from '../asset/Logo/GagasMedia.png'
import IARC from '../asset/Logo/IARC.jpeg'
import Hallyu from '../asset/Logo/Indonesia_Hallyu_Book_Club.png'
import Tera from '../asset/Logo/Indonesia_Tera.png'
import JRR from '../asset/Logo/JRR.png'
import KumpulBaca from '../asset/Logo/kumpulbaca-final-logo-hole.png'
import AHC from '../asset/Logo/Logo_AHC_Indonesia_(1).png'
//import Banana from '../asset/Logo/Logo_baNANA.png'
import Festapora from '../asset/Logo/Logo_FESTApora.png'
//import Gradien from '../asset/Logo/Logo_Gradien.png'
import Gulali from '../asset/Logo/Logo_Gulali_Festival.png'
import Himaja from '../asset/Logo/logo_himaja.png'
import INARI from '../asset/Logo/LOGO_INARI.png'
//import JGG from '../asset/Logo/Logo_Jakarta_Good_Guide.png'
import LTI from  '../asset/Logo/LOGO_LTI.png'
import Naratama from '../asset/Logo/Logo_Naratama.PNG'
import Pickpockie from '../asset/Logo/logo_pickpockie_(1).png'
import Rangkas from '../asset/Logo/Logo_rangkas_gold.png'
import Rantaikata from '../asset/Logo/LOGO_RANTAIKATA_PNG_(1)_(2).png'
import SekolahKembang from '../asset/Logo/logo_sekolah_kembang.png'
import Transmedia from '../asset/Logo/Logo_TransMedia_Pustaka.png'
import Lucky from '../asset/Logo/lucky_logo2_pp.png'
//import Mili from '../asset/Logo/MILI_KECIL.png'
import NEX from '../asset/Logo/NEX.png'
import NFS from '../asset/Logo/NFS_PNG-01.png'
import Baca from '../asset/Logo/Penerbit_BACA_PNG.png'
import PostPress from '../asset/Logo/Post_Press.png'
import Sijeuni from '../asset/Logo/Readersijeuni.JPG'
import Rekata from '../asset/Logo/Rekata_White.png'
import Rotasi from '../asset/Logo/Rotasi_Books.png'
import Santai from '../asset/Logo/Santai_Ngobrolin_Buku_PNG-02.png'
import Scarlet from '../asset/Logo/Scarlet_Pen_Award.jpg'
import Shadow from  '../asset/Logo/Shadow_Stories.png'
import Tanda from '../asset/Logo/Tanda_Baca.png'
import TBC from '../asset/Logo/THE_BOOK_CLUB_Makassar.png'
import JapanFoundation from '../asset/Logo/The_Japan_Foundation_-_B.png'
import Tango from '../asset/Logo/Logo_Susu_Tango.png'
import Peruri from '../asset/patjarmerah kecil/Logo/01. peruri.png'
import Mbloc from '../asset/patjarmerah kecil/Logo/02. M Bloc.png'
import Matalokal from '../asset/patjarmerah kecil/Logo/03. Matalokal.png'
import ABC from '../asset/patjarmerah kecil/Logo/ABC Reading Club.png'
import Akhir from '../asset/patjarmerah kecil/Logo/AKHIRPEKAN1.png'
import Anagram from '../asset/patjarmerah kecil/Logo/anagram.png'
import Banana from '../asset/patjarmerah kecil/Logo/baNANA.jpeg'
import Mojok from '../asset/patjarmerah kecil/Logo/Buku Mojok.png'
import Bukune from '../asset/patjarmerah kecil/Logo/Bukune_Logo.jpg'
import DID from '../asset/patjarmerah kecil/Logo/Detectives ID.jpg'
import Dextone from '../asset/patjarmerah kecil/Logo/Dextone.png'
import EIB from '../asset/patjarmerah kecil/Logo/Ekspedisi Indonesia Baru.png'
import Erudio from '../asset/patjarmerah kecil/Logo/Erudio Indonesia.png'
import Folxtale from '../asset/patjarmerah kecil/Logo/Folxtale.PNG'
import Gagasmedia from '../asset/patjarmerah kecil/Logo/GagasMedia.jpeg'
import Gradien from '../asset/patjarmerah kecil/Logo/Gradien Mediatama.jpg'
import Grafie from '../asset/patjarmerah kecil/Logo/Grafie.png'
import JGG from '../asset/patjarmerah kecil/Logo/Jakarta Good Guide.png'
import Kaya from '../asset/patjarmerah kecil/Logo/Kaya Baca.png'
import Klub from '../asset/patjarmerah kecil/Logo/Klub Buku Nyala Matahari.png'
import Lensa from '../asset/patjarmerah kecil/Logo/Lensa Anak Terminal.png'
import Bukuku from '../asset/patjarmerah kecil/Logo/Logo Bukuku.Club.png'
import Mili from '../asset/patjarmerah kecil/Logo/MILI KECIL.png'
import Noura from '../asset/patjarmerah kecil/Logo/Noura Kids.jpeg'
import Haru from '../asset/patjarmerah kecil/Logo/Penerbit Haru.png'
import Rembuku from '../asset/patjarmerah kecil/Logo/Rembuku.PNG'
import RDP from '../asset/patjarmerah kecil/Logo/Rumah Dongeng Pelangi.png'
import RSJ from '../asset/patjarmerah kecil/Logo/Rupa Swara Jiwa.png'
import Sare from '../asset/patjarmerah kecil/Logo/Sare Band.png'
import Kembang from '../asset/patjarmerah kecil/Logo/Sekolah Kembang.png'
import Semesta from '../asset/patjarmerah kecil/Logo/Semesta Kumbo Logo.png'
import SSS from '../asset/patjarmerah kecil/Logo/Seni Sama Sama.png'
import TA from '../asset/patjarmerah kecil/Logo/Tentang Anak.png'
import Tsugaeda from '../asset/patjarmerah kecil/Logo/Tsugaeda.png'
import YMS from '../asset/patjarmerah kecil/Logo/Yovie Music School.PNG'
import Aerputh from '../asset//Logo/aerputh.jpeg'

export const KawanPatjar = [
  /* Haru,
  POS,
  Clan,
  Mojok,
  Bukune,
  Detectives,
  Ea,
  Gagasmedia,
  IARC,
  Hallyu,
  Tera,
  JRR,
  KumpulBaca,
  AHC,
  Banana,
  Festapora,
  Gradien,
  Gulali,
  Himaja,
  INARI,
  JGG,
  LTI,
  Naratama,
  Pickpockie,
  Rangkas,
  Rantaikata,
  SekolahKembang,
  Transmedia,
  Lucky,
  Mili,
  NEX,
  NFS,
  Baca,
  PostPress,
  Sijeuni,
  Rekata,
  Rotasi,
  Santai,
  Scarlet,
  Shadow,
  Tanda,
  TBC,
  JapanFoundation,
  Tango */
  Peruri,
  Mbloc,
  Matalokal,
  ABC,
  Akhir,
  Anagram,
  Banana,
  Mojok,
  Bukune,
  DID,
  Dextone,
  EIB,
  Erudio,
  Folxtale,
  Gagasmedia,
  Gradien,
  Grafie,
  JGG,
  Kaya,
  Klub,
  Lensa,
  Bukuku,
  Mili,
  Noura,
  Haru,
  Rembuku,
  RDP,
  RSJ,
  Sare,
  Kembang,
  Semesta,
  SSS,
  TA,
  Tsugaeda,
  YMS,
  Aerputh,
]