import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {AiOutlineCalendar, AiOutlineWarning} from "react-icons/ai";
import Palette from "../../util/Palette";
import {MdError, MdLocationOn} from "react-icons/md";
import {TiLocationOutline, TiTicket} from "react-icons/ti";
import {useCookies} from "react-cookie";
import Session from "../../models/Session";
import PapermoonTicketNode from "../reusable/PapermoonTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import moment from "moment";
import ScheduleNode from "../reusable/ScheduleNode";
import GlobalData from "../../util/GlobalData";
import _ from "lodash";
import SessionModal from "../reusable/SessionModal";
import {useHistory} from 'react-router-dom'
import Banner from "../../asset/2024/patjarkeciljuni/bannerpatjarkita.jpeg"

let firstLoad = true

export default function PatjarmerahKecilPage(props) {

    const [isLoginFormShown, setIsLoginFormShown] = useState(false);
    const [loginMessage, setLoginMessage] = useState("")

    const buyTicketRef = useRef(null);
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = cookie.lang !== "id";
    const [fbaSessions, setFbaSessions] = useState([]);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const history = useHistory()

    const [isToCModalOpen, setIsToCModalOpen] = useState(false)
    const [isPPModalOpen, setIsPPModalOpen] = useState(false)

    const [isSessionModalShow, setIsSessionModalShown] = useState(false)
    const [isPaymentModalShow, setIsPaymentModalShow] = useState(false)
    const [paymentUrl, setPaymentUrl] = useState("")
    const [selectedSession, setSelectedSession] = useState(null)

    const [groupedSessions, setGroupedSessions] = useState(null)

    function handleResize() {
        console.log('width: ' + window.innerWidth)

        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }


    const fetchSessions = async () => {

        console.log("is calling with " + GlobalData.token)

        let sessions = await new Session().getAllPatjarmerahKecilSessions();

        let defaultId = 0

        if (firstLoad) {
            if (props.match.params.id) {
                defaultId = props.match.params.id
                console.log("def", defaultId)
            }
            firstLoad = false
        } else {
            console.log("first load is false", props.match.params)
        }

        console.log("DEFAULT Id", defaultId)
        // let formattedSession = _.groupBy(sessions, (obj) => {
        //     return moment(obj.start_time).locale('id').format("dddd, DD MMMM YYYY")
        // })

        if (defaultId > 0) {
            let session = sessions.find(e => {
                console.log(defaultId, parseInt(e.id), parseInt(e.id) === parseInt(defaultId))
                return parseInt(e.id) === parseInt(defaultId)
            })
            console.log("selected session", sessions)
            if (session) {
                // this.setState({
                //     ...this.props,
                //     selectedSession: session,
                //     isSessionModalShown: true
                // })
                setSelectedSession(session)
                setIsSessionModalShown(true)
            }

        }

        let formattedSession = _.groupBy(sessions, (obj) => {
            return moment(obj.start_time).locale('id').format("dddd, DD MMMM YYYY")
        })

        console.log(sessions)
        setFbaSessions(sessions)

        setGroupedSessions(formattedSession)

    }

    useEffect(() => {

        fetchSessions()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

        // ApplicationActivities.sendActivity({
        //     type: "PAPERMOON",
        //     description: "",
        //     timestamp: new Date(),
        //     os: isMobile ? "mobile-website" : "website"
        // })
    }, []);

    const showLogin = () => {
        setIsLoginFormShown(true)
        setLoginMessage('Harap login untuk melanjutkan pendaftaran')
        setIsSessionModalShown(false)
    }

    const renderSessionOfDay = (sessionOfDay) => {
        return <>
            {sessionOfDay.map((s, key) => {
                return <ScheduleNode
                    session={s}
                    key={key}
                    id={s.id}
                    image={s.image_url}
                    speaker={s.session_speaker}
                    title={s.session_name}
                    timeStart={moment(s.start_time)}
                    timeEnd={moment(s.end_time)}
                    xl={{span: 4, offset: key % 2 ? 0 : 0}}
                    md={4}
                    xs={12}

                    onClick={(session) => {
                        console.log("sid" + s.id)
                        setSelectedSession(session)
                        setIsSessionModalShown(true)
                        history.push("/patjarkecil/" + s.id)

                    }}
                />
            })}
        </>
    }


    const renderSessions = () => {
        if (!groupedSessions) {
            return;
        }

        let dateStrings = Object.keys(groupedSessions)

        return dateStrings.map((dateString, key) => {
            return <>
                <Row style={{
                    justifyContent: isMobile ? "center" : "flex-start"
                }}>
                    <h4>{dateString}</h4>
                </Row>
                <Row>
                    {renderSessionOfDay(groupedSessions[dateString])}
                </Row>
            </>
        })
    }

    return (
        <PageWrapper
            loginShow={isLoginFormShown}
            message={loginMessage}
            customOnHide={isLoginFormShown ? () => setIsLoginFormShown(false) : null}
            headerActive={'patjarmerah-kecil'}
        >


            <Modal
                size="sm"
                centered
                onHide={() => {
                    setIsPaymentModalShow(false)
                    window.location.reload();
                }}
                show={isPaymentModalShow}
            >
                <Modal.Header closeButton>
                    Informasi
                </Modal.Header>
                <Modal.Body>
                    <div>Untuk melanjutkan
                        pembayaran, silahkan menekan <a href={paymentUrl}>tautan ini</a>.
                    </div>
                </Modal.Body>

            </Modal>

            <SessionModal
                show={isSessionModalShow}
                session={selectedSession}
                setPending={() => {
                    fetchSessions()
                    setIsSessionModalShown(false)
                }}
                onHide={() => {
                    setSelectedSession(null)
                    setIsSessionModalShown(false)

                    window.history.pushState({}, '', "/patjarkecil");
                    history.push("/patjarkecil")
                }}
                showLogin={showLogin}
                fetchSessions={fetchSessions}
                showPaymentModal={(paymentUrl) => {
                    setIsSessionModalShown(false)
                    setIsPaymentModalShow(true)
                    setPaymentUrl(paymentUrl)
                }}
            />

            <Container fluid style={{backgroundColor: "white"}}>
                <Container style={{paddingTop: '1em', marginBottom: 70}}>

                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: 40}}>
                        <Col xl={9}>
                            <img src={Banner}
                                 style={{width: '100%'}}/>
                        </Col>
                    </Row>

                    {/*<Row style={{display: 'flex', justifyContent: 'center', marginTop: 50, marginBottom: 25}}>*/}
                    {/*    <Col xl={9}>*/}
                    {/*        <b style={{fontSize: 32}}>Tentang Festival Buku Asia</b><br/><br/>*/}
                    {/*        <p style={{wordSpacing: "150%", textAlign: 'justify'}}><b>*/}
                    {/*            Festival Buku Asia adalah pesta literasi yang digagas oleh Penerbit Haru dan*/}
                    {/*            diselenggarakan secara daring. Festival yang berisi rangkaian diskusi, talkshow, dan*/}
                    {/*            lokakarya ini diadakan sebagai wadah bagi semua orang yang mencintai buku dan budaya*/}
                    {/*            Asia untuk berjejaring dan mendiskusikan budaya Asia yang kaya lewat buku.*/}
                    {/*        </b>*/}
                    {/*        </p>*/}
                    {/*    </Col>*/}

                    {/*</Row>*/}

                    {/*<Row style={{*/}
                    {/*    display: 'flex', justifyContent: 'center', marginBottom: 24*/}
                    {/*}}>*/}
                    {/*    <Col xl={9}>*/}
                    {/*        <b style={{fontSize: 32}}>Jadwal Sesi</b>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: 50, marginBottom: 25}}>
                        <Col md={9}>
                            <Container fluid>
                                {renderSessions()}
                            </Container>
                        </Col>

                    </Row>

                    <Col xl={{span: 4, offset: 1}}
                         md={6}
                         xs={12}
                    />


                </Container>

                <Modal
                    size="md"
                    onHide={() => {
                        setIsToCModalOpen(false)
                    }}
                    show={isToCModalOpen}
                >
                    <Modal.Header closeButton>
                        {isEN ? "Terms and Condition" : "Syarat dan Ketentuan"}
                    </Modal.Header>
                    <Modal.Body>
                        <ul>
                            <li>
                                {isEN ? "" : "1 tiket luring / offline berlaku untuk 1 orang."}
                            </li>
                            <li>
                                {isEN ? "" : "Apabila anak ditemani oleh pendamping maka pendamping wajib membeli tiket."}
                            </li>
                            <li>
                                {isEN ? "All Sales are Final." : "Transaksi yang dilakukan bersifat final."}
                            </li>
                            <li>
                                {isEN ? "Items that have been purchased cannot be returned." : "Tiket yang dibeli tidak bisa ditukar atau dikembalikan."}
                            </li>
                            <li>
                                {isEN ? "" : "Harap menyelesaikan pembayaran dalam waktu 2 jam."}
                            </li>
                            <li>
                                {isEN ? "" : "Pembayaran akan otomatis batal apabila tidak menyelesaikan pembayaran dalam waktu 2 jam."}
                            </li>
                            {/*<li>*/}
                            {/*    {isEN ? "Please complete your payment 2 hours within order." : "Harap menyelesaikan pembayaran 2 jam setelah pendaftaran."}*/}
                            {/*</li>*/}
                        </ul>
                    </Modal.Body>

                </Modal>

                <Modal
                    size="lg"
                    onHide={() => {
                        setIsPPModalOpen(false)
                    }}
                    show={isPPModalOpen}
                >
                    <Modal.Header closeButton>
                        {isEN ? "Pay With Paypal" : "Pembayaran Menggunakan Paypal"}
                    </Modal.Header>

                    <Modal.Body>
                        As an alternative method to accomodate overseas international payment, we now accept payment
                        using Paypal.<br/>

                        <br/>
                        <br/>

                        <b>How to Confirm Your Paypal Payment</b>
                        <ol>
                            <li>
                                Create an account in patjarmerah.com
                            </li>
                            <li>
                                Pay with Paypal using the following <a
                                href={"https://www.paypal.com/paypalme/papermoonpuppet"} target={"_blank"}>link</a>
                            </li>
                            <li>
                                Confirm your payment via email to <a
                                href="mailto: renjana@patjarmerah.com">renjana@patjarmerah.com</a>. In the email, please
                                include your <b>payment proof</b>, registered <b>email</b>, and your <b>preferred show
                                time</b>. Please also use the subject <b>Paypal Payment Confirmation</b>.
                            </li>
                            <li>
                                Please wait for our admins to process your payment and we will get back to you.
                            </li>
                            {/*<li>*/}
                            {/*    {isEN ? "Please complete your payment 2 hours within order." : "Harap menyelesaikan pembayaran 2 jam setelah pendaftaran."}*/}
                            {/*</li>*/}
                        </ol>

                        <br/>

                        <b>Disclaimer</b>

                        <ol>
                            <li>
                                Please be aware that payment using paypal is confirmed manually by our admins, so it may
                                takes several hours before confirmation.
                            </li>
                            <li>
                                If your preferred show time is full, we will put you in another time.
                            </li>
                            <li>
                                We recommend you to pay using other method first before using paypal.
                            </li>
                        </ol>
                    </Modal.Body>

                </Modal>

            </Container>
        </PageWrapper>
    )
}
